import TicketIcon from "App/Components/IconsComponents/TicketsIcon";
import validationCheck from "./validationCheck";
import MembershipIcon from "App/Components/IconsComponents/MembershipIcon";
import GamingIcon from "App/Components/IconsComponents/GamingIcon";
import AssetBackedIcon from "App/Components/IconsComponents/AssetBackedIcon/Index";

export const getTwoDigitCount = (num = 0) => {
    return num.toString().padStart(2, 0)
}

export const getHexToStr = (hexStr) => {
    let hex = hexStr?.toString();
    let strResult = '';
    for (let n = 0; n < hex?.length; n += 2) {
        strResult += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
    }
    return strResult;
}

export const gcd = (width, height) => {
    return (height === 0) ? width : gcd(height, width % height);
}

export const aspectRatio = (width, height) => {
    const changedWidth = !!Number(width) ? Number(width) : 1
    const changedHeight = !!Number(height) ? Number(height) : 1
    const gcdValue = gcd(changedWidth, changedHeight);
    const arw = changedWidth / gcdValue;
    const arh = changedHeight / gcdValue;
    return `${Number((arw / arw))} : ${Number((arh / arw).toFixed(2))}`;
}

export const safeJsonParse = (json) => {
    let result = {}
    try {
        result = JSON.parse(json)

    } catch (error) {
        result = {}

    }
    return result;
}

export const objectToFormdata = (payload = {}) => {
    const formData = new FormData();
    for (const key in payload) {
        if (Object.hasOwnProperty.call(payload, key)) {
            const element = payload[key];
            if (element && typeof element === 'object' && !(element instanceof Blob)) {
                formData.append(key, JSON.stringify(element))
            }
            else {
                formData.append(key, element)
            }

        }
    }
    return formData
}

export const extractFormFieldKeys = (formValues, payload) => {
    let result = {};
    for (const key in formValues) {
        if (Object.hasOwnProperty.call(formValues, key) && Object.hasOwnProperty.call(payload, key)) {
            const element = payload[key];
            result[key] = element;
        }
    }
    return result
}
export const convertPriceToAda = (priceValue) => {
    const price = (priceValue / 1000000);
    return price;
}

export const convertJsonToArray = (data) => {
    try {
        const convertData = JSON.parse(data);
        return typeof convertData === 'object' ? convertData : [];
    } catch (error) {
        return [];
    }
}

export const getWidthHeight = (file) =>{
    let width;
    let height;
    const reader = new FileReader();
reader.onload = function (e) {
  const img = new Image();
  img.onload = function () {
   width = img.width;
   height = img.height;

  };
  img.src = e.target.result;
};
reader.readAsDataURL(file);

return {width,height}
}

export const objectToNull = (payload = {}) => {
    let obj={}
    for (const key in payload) {
        if (Object.hasOwnProperty.call(payload, key)) {
            const element = payload[key];
        obj[key] = element?element:null

        }
    }
    return obj
}

export const copyClipHandler = (value,callback) => {
    var textArea = document.createElement("textarea");
    textArea.value = value;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.width = ".09px";
    textArea.style.opacity = "0";

    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    callback(true)
}

export const categorySort = (data) => {
    let updatedList = data
      ?.sort((fs, sec) => fs.name.localeCompare(sec.name))
      ?.map((el) => {
        return {
          ...el,
          subcategories: el?.subcategories?.length
            ? el?.subcategories?.sort((fs, sec) =>
                fs.name.localeCompare(sec.name)
              )
            : el?.subcategories,
        };
      });
  
    return updatedList;
  };

  
export function formatLargeNumber(num) {
    if (!num) return null;

    const number = parseFloat(num);
    if (!isNaN(number)) {
        // Define the threshold for each unit
        const thresholds = [
            { value: 1e12, symbol: 't' }, // trillion
            { value: 1e9, symbol: 'b' },  // billion
            { value: 1e6, symbol: 'm' },  // million
            { value: 1e3, symbol: 'k' }   // thousand
        ];

        // Loop through the thresholds to find the appropriate symbol
        for (let i = 0; i < thresholds.length; i++) {
            if (num >= thresholds[i].value) {
                // If the number is greater than or equal to the threshold, format it accordingly
                return (num / thresholds[i].value).toFixed(1) + thresholds[i].symbol;
            }
        }

        // If the number is smaller than the first threshold, return it as is
        return num.toString();
    } else {
        return null;
    }
}

export const numberWithCommas = (num) => {
    if (!num) return null;

    const number = parseFloat(num);
    if (!isNaN(number)) {
        return number.toLocaleString();
    } else {
        return null;
    }
};

export const getBLOBUrl = (blob) => {
    try {
        return URL.createObjectURL(blob)
    } catch (error) {
        return blob
    }
}

export const getBlockChainLable = ({blockChainList = [], blockChainId = '', blockChainCode = '', marketplaceChains = []}) => {
    const selectedBlockchain = blockChainList?.find((item)=> (item?.id === blockChainId || item?.code === blockChainCode));
    if (marketplaceChains?.includes(selectedBlockchain?.code))
    {
        return "Marketplace Collection URL";
    }
    return selectedBlockchain?.code === 'cardano' ? 'Policy ID' : (selectedBlockchain?.code === 'solana' ? 'Collection ID' : 'Smart Contract');    
}

export const getOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
        return "Android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
        return "Safari";
    }
    return "unknown";
}
export const isMobileOS = () => {
    let os = getOperatingSystem();
    return os==="Windows Phone"||os==="Android"||os==="iOS";
}
export const FindOddOrEven=(num)=>{
    let isOdd =num%2!==0;
    let isEven = num%2===0;
    return {isOdd,isEven}
}

export const thousandCommaSeparator =(amount)=>{
    return amount ? amount?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0; 
 }
 export const  formatNumberWithThousand=(num)=> {
     if (num >= 1000) {
         return thousandCommaSeparator((num / 1000)?.toFixed(1)?.replace(/\.0$/, '')) + 'k';
     }
     return num ? num?.toString() : 0;
 }
export const stringImageSrcToBlob = async (imgSrc) => {    
    const response = await fetch(imgSrc);
    const blob = await response.blob();
    if (['image/jpeg','image/jpg','image/png']?.includes(blob.type)) {
            // If the image is not webp, return the original blob as a File
            return new File([blob], 'image.jpg', { type: blob.type });
    } else {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = URL.createObjectURL(blob);
  
        img.onload = () => {
          // Draw the image on a canvas
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
  
          // Convert the canvas content to a JPEG Blob using a Promise
          canvas.toBlob((jpegBlob) => {
            if (jpegBlob) {
              const file = new File([jpegBlob], 'image.jpg', { type: 'image/jpeg' });
              resolve(file);
            } else {
              reject(new Error('Failed to convert image to JPEG format.'));
            }
          }, 'image/jpeg');
        };
  
        img.onerror = (error) => reject(error);
      });
    }
  };

  export const loadArraySegment = ({list = [], pageNo = 0, itemsPerPage = 0}) => {
    const offset = (pageNo - 1) * itemsPerPage;
    const limit = (((pageNo - 1) * itemsPerPage) + itemsPerPage);
    return list?.slice(offset, limit);
}

export const sequenceUpdate =(list, from, to) => {
    const listClone = [...list];
    if (from < to) {
      listClone.splice(to + 1, 0, listClone[from]);
      listClone.splice(from, 1);
    } else if (to < from) {
      listClone.splice(to, 0, listClone[from]);
      listClone.splice(from + 1, 1);
    }
    let listCloneUpdate = [...listClone]?.map((el,i)=>{
        return {...el,sequence:i+1}
    })
    return listCloneUpdate;
  }
  export const bytesToHex = (bytes) => {
    return Array.from(
      bytes,
      byte => byte.toString(16).padStart(2, "0")
    ).join("");
  }
  export const UTF8ToHex = (string) => {
    const sanitizedString = string.replace(/[^a-zA-Z0-9]/g, '');
    return bytesToHex(new TextEncoder().encode(sanitizedString));
}

export const splitWord=(value,num)=>{
    return value?.length < num
        ? value
        : value?.slice(0, num/2) +
        "..." +
        value?.slice(-(num/2))
  }

  export const deepCopy = (arr) => {
    return JSON.parse(JSON.stringify(arr));
}
export const getShortDate = (value) => {
    
    if ((value?.toString()??"") === "") return "";
    let formattedDate=  new Date(value).toLocaleDateString("en-us", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      return  (formattedDate && formattedDate !== 'Invalid Date') ? formattedDate : ''
  }

export const getMetadata = (data, favKey) => {
    let image = '';
    if (data.onchain_metadata?.asset?.ipfs) {
        image = `${data.onchain_metadata?.asset?.url}${data.onchain_metadata?.asset?.ipfs}`
    } else if (data.metadata?.logo) {
        image = `data:image/png;base64,${data.metadata?.logo}`;
    }
    let files = [];
    if(data?.onchain_metadata?.files?.length) {
        let thumbimage = data.onchain_metadata?.image || image
        files = data?.onchain_metadata?.files?.map((item => {
            let src = JSON.parse(JSON.stringify(item?.src??item?.fileFromsUrl??""))
            let mediaType = JSON.parse(JSON.stringify(item?.mediatype??item?.mediaType??item?.mimeType??""))
            if(Array.isArray(src)) {
                src = src.join('');
            }
            if(src?.includes('html')) {
                item.sType = 'html';
            } else if(src?.includes('ipfs')) {
                // src =src;
                item.sType = 'ipfs';
            } else if(src?.includes('base64')) {
                item.sType = 'base64';
            }

            if(mediaType?.includes('html')) {
                item.mType = 'html';
            } else if(mediaType?.includes('image')) {
                item.mType = 'image';
            } else if(mediaType?.includes('video')) {
                item.mType = 'mp4';
            } else if(mediaType?.includes('pdf')) {
                item.mType = 'pdf';
            } else if(mediaType?.startsWith('model')) {
                item.mType = 'model';
            } else if(mediaType?.includes('epub')) {
                item.mType = 'epub';
            }
            item.newsrc = src;
            item.thumbimage = thumbimage;
            return item
        }))
    } else if (data?.onchain_metadata?.chain) {
        let f
        if (data?.onchain_metadata?.extra_metadata?.artifactUri && data?.onchain_metadata?.extra_metadata?.formats?.length)
        {
            f = data?.onchain_metadata?.extra_metadata?.formats.filter(f=>f.uri === data?.onchain_metadata?.extra_metadata?.artifactUri)?.[0];
        }
        else if (data?.onchain_metadata?.extra_metadata?.generatorUri)
        {
            f = {
                uri: data?.onchain_metadata?.extra_metadata?.generatorUri,
                mimeType: "html/text"                
            }

            if (f.uri.startsWith("ipfs://"))
            {
                f.uri = f.uri.replace("ipfs://","https://cur8.myfilebase.com/ipfs/");
            }
            else if (f.uri.startsWith("onchfs://"))
            {
                f.uri = f.uri.replace("onchfs://","https://onchfs.fxhash2.xyz/");
            }

            if (data?.onchain_metadata?.extra_metadata?.iterationHash)
            {   
                f.uri += `/?fxhash=${data?.onchain_metadata?.extra_metadata?.iterationHash}`;
            }
        }
        if (f)
        {
            let item = {
                "src": f.uri,
                "mediaType": f.mimeType,                    
                "nftcdnfile": f.uri
            }                    
            let src = f.uri;
            if(src?.includes('html')) {
                item.sType = 'html';
            } else if(src?.includes('ipfs')) {                        
                item.sType = 'ipfs';
            } else if(src?.includes('base64')) {
                item.sType = 'base64';
            }

            let mediaType = f.mimeType;
            if(mediaType?.includes('html') || mediaType === 'application/x-directory') {
                item.mType = 'html';
            } else if(mediaType?.includes('image')) {
                item.mType = 'image';
            } else if(mediaType?.includes('video')) {
                item.mType = 'mp4';
            } else if(mediaType?.includes('pdf')) {
                item.mType = 'pdf';
            } else if(mediaType?.startsWith('model')) {
                item.mType = 'model';
            } else if(mediaType?.includes('epub')) {
                item.mType = 'epub';
            }
            files.push(item);
        }
        if (data?.onchain_metadata?.video_url)
        {
            files.push({
                "src": data?.onchain_metadata?.video_url,
                "nftcdnfile": data?.onchain_metadata?.video_url.replace("ipfs://", "https://cur8.myfilebase.com/ipfs/"),                    
                "mediaType": "video",
                "mType": "mp4"                    
            });
        }
        if (data?.onchain_metadata?.extra_metadata?.animation_original_url)
        {
            files.push({
                "src": data?.onchain_metadata?.extra_metadata?.animation_original_url,
                "nftcdnfile": data?.onchain_metadata?.extra_metadata?.animation_original_url.replace("ipfs://", "https://cur8.myfilebase.com/ipfs/"),                    
                "mediaType": "html",
                "mType": "html"                    
            });
        }
    } else{
        let src =  data.onchain_metadata?.image || image;
        files = [ {
            "src": src,
            "name": data.onchain_metadata?.name || data.metadata?.name || data.name,
            "mediaType": "image/png",
            "mType": "image",
            "sType": "ipfs",
            "newsrc": src,
            nftcdnfile: data?.nftcdnimage
        } ]
    }
    return {
        collectionName: data.collectionName??data.onchain_metadata?.collectionName,
        collectionImage: data.collectionImage,
        collectionImage256: data.collectionImage256,
        collectionImage512: data.collectionImage512,
        collectionImage1024: data.collectionImage1024,
        collectionId: data.collectionId,
        assetCount: data.assetCount,
        ownerCount: data.ownerCount,
        countDate: getShortDate(data.countDate),
        asset: data.asset,
        policy: data.policy_id,
        asset_name: data.asset_name,
        name: data?.onchain_metadata?.name || data?.metadata?.name || data?.onchain_metadata?.title || data?.metadata?.title || data?.onchain_metadata?.["Asset Title"] || data?.metadata?.["Asset Title"] || data?.name,
        image: data.onchain_metadata?.image || image,
        description: data.onchain_metadata?.description || data.metadata?.description,
        type: data.onchain_metadata?.type ?? data.onchain_metadata?.Type,
        files: files,
        quantity: 1,
        isCollectionHided:data?.isCollectionHided,
        isCollectionFavourite: data[favKey],
        isHidedCollectionAsset:data?.isHidedCollectionAsset,
        isFavoriteCollectionAsset: data?.isFavoriteCollectionAsset,
        isFavourite: data.isFavouriteNFT,
        networkType: data.networkType,
        complexity: data.onchain_metadata?.complexity,
        value: data.onchain_metadata?.value,
        color: data.onchain_metadata?.color,
        circle: data.onchain_metadata?.circle,
        priceSource: data.onchain_metadata?.priceSource,
        mintDate: getShortDate(data.mintDate),
        lockDate: getShortDate(data.lockDate),
        mintDateFull: data.mintDate,
        lockDateFull: data.lockDate,
        walletId: data?.walletId,
        ownersCount: data?.ownersCount,
        items: data?.items,
        mint_or_burn_count: data?.mint_or_burn_count,
        category: data?.category??{name: data.categoryName},
        categoryId: data?.categoryId,
        firstSubcategoryId: data?.firstSubcategoryId,
        secondSubcategoryId: data?.secondSubcategoryId,
        onchain_metadata: data.onchain_metadata,
        community: {id: data.communityId, name: data.communityName, displayPicture256: data.communityDisplayPicture256, communitySlug: data.communitySlug },
        aspectRatios: data.aspectRatios,
        formats: data.formats,
        collectionEditionsId: data.collectionEditionsId,
        collectionStorageId: data.collectionStorageId,
        tags: data.tags,
        types: data.types,
        utility:data?.utility,
        id:data?.id,
        nftcdnimage: data.nftcdnimage,
        nftcdnimage256: data.nftcdnimage256,
        nftcdnimage512: data.nftcdnimage512,
        nftcdnimage1024: data.nftcdnimage1024,
        termsId: data.termsId,
        subCategoryName:data?.subCategoryName,
        showcase: data.showcase,
        collage: data.collage,
        metrics: data?.metrics,
        marketplace: data?.marketplace,
        minted: data?.minted,
        nfttype: data?.nfttype,
        collectionCollectionId: data?.collectionCollectionId,
        blockchainType: data?.blockchainType,
        contractAddress: (data?.blockchainType?.code !== 'solana' && data?.blockchainType?.code !== 'bitcoin') ? data?.contractAddress : (data?.onchain_metadata?.contract_address ?? data?.contractAddress),
        createdAt: data?.createdAt,
        assetDetailsUpdatedAt: data?.assetDetailsUpdatedAt,
        collectionUpdatedAt: data?.collectionUpdatedAt
    }
}

export const removeDuplicateByElement = (array, key) => {
    const uniqueIds = [];
    // eslint-disable-next-line array-callback-return
    array.filter(ele => {
        const includeArr = uniqueIds.some(x => x[key] === ele[key]);
        if(!includeArr)
            uniqueIds.push(ele);
    })
    return uniqueIds;
}

export const capitalizeFirstLetter = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);

}

export const scrollToTop = (x = 0, y = 0, element = window, disableSmooth = false) => {
    element?.scrollTo({
        top: y,
        left: x,
        behavior: disableSmooth ? 'auto' :"smooth"
    });
}

export const scrollToFirstInvalidField = () => {   
    const firstInvalidField = document.querySelector('[data-required="true"][data-empty="true"], [data-invalid="true"]');
    if (firstInvalidField) {
        firstInvalidField.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
};

export const updateElementData=(data)=>{
    let keyName=Object.keys(data)[0];
    const formField = document.querySelector(`[data-name="${keyName}"]`);
    if (formField) {
      formField.setAttribute('data-empty', !data[keyName] ? 'true' : 'false');
      const type = formField.getAttribute("data-type");
      if(type){
        formField.setAttribute('data-invalid',(data[keyName]? validationCheck({ type, value:data[keyName] }) ? 'true' : 'false':'false'));
      }
    }
}

export const setUtilityIcon=(utility)=>{
    switch(utility){
        case "Tickets":
            case "Ticket":
           return TicketIcon ;
        case "Membership":
            case "Memberships":
           return MembershipIcon;
        case "Gaming":
           return GamingIcon;
        case "Asset-Backed":
          return AssetBackedIcon;
        default:
            return ""
    }
    }

    export const setUtilityDescription=(utility)=>{
        switch(utility){
            case "Tickets":
                case "Ticket":
               return "NFTs that serve as digital tickets, granting access to physical or virtual events, experiences, and venues" ;
            case "Membership":
                case "Memberships":
               return "NFTs that function as digital membership cards, providing access to exclusive content, services, and holder benefits";
            case "Gaming":
               return "NFTs that serve as functional in-game assets, including playable characters, equipment, cosmetic items, and virtual land";
            case "Asset-Backed":
              return "NFTs that represent ownership rights in physical assets from redeemable products to fractional property ownership";
            default:
                return ""
        }
    }

    export const selectedCollectionUtilityFromSelectedListHandler = (id,utilities) => {
        let collectionUtilities =utilities;
          collectionUtilities =utilities?.find(el=>el?.collectionUtilityId===Number(id))
         return collectionUtilities
      };